<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #000">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center pt-7>
      <v-flex xs11 text-left>
        <v-layout wrap justify-center>
          <v-flex xs12>
            <v-layout wrap>
              <v-flex xs12 align-self-center text-center py-4>
                <span style="font-weight: bold" class="popregular ml"
                  >Testimonials</span
                >
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 lg12 py-7 v-if="icons">
            <v-carousel
              cycle
              :interval="12000"
              height="auto"
              hide-delimiter-background
            >
              <v-carousel-item v-for="(item, i) in icons" :key="i">
                <v-card outlined>
                  <v-layout wrap pa-5 class="popregular">
                    <!-- <v-flex xs12>
                      <v-layout wrap justify-center>
                        <v-flex xs6 sm6 md2 lg2 xl2text-center>
                          <v-avatar size="100%">
                            <v-img :src="item.name"></v-img>
                          </v-avatar>
                        </v-flex>
                      </v-layout>
                    </v-flex> -->
                    <v-flex
                      xs12
                      pt-4
                      style="font-size: 17px; font-weight: bolder"
                      text-center
                    >
                      {{ item.heading }}
                    </v-flex>
                    <v-flex xs12 pt-3 text-center style="color: #e27725">{{
                      item.location
                    }}</v-flex>
                    <v-flex
                      xs12
                      pt-4
                      pt-3
                      text-center
                      style="text-align: justify"
                      >{{ item.text }}</v-flex
                    >
                  </v-layout>
                </v-card>
              </v-carousel-item>
            </v-carousel>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center pb-16  pt-10>
      <v-flex xs11 style="border: 1px solid black;">
        <v-flex xs12 pt-8 text-center>
          <span style="font-size:17px;font-weight:bolder">Add Impact to Your Inbox
            <br />
            <span style="font-size:13px">
              Get our emails to stay in the know
            </span>
          </span>
        </v-flex>
        <div class="popregular" id="mc_embed_signup">
          <form action="https://wti.us20.list-manage.com/subscribe/post?u=2c47e36b829020c293c520c3f&amp;id=e29f2715de&amp;f_id=009c0deaf0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
            <div id="mc_embed_signup_scroll">
            

              <v-row no-gutters justfi-center>
                <v-col class="popregular" cols="12" xs="12" sm="12" md="3" lg="3" xl="3" >
                  <v-sheet class="ma-2 pa-2">
                    <div class="mc-field-group">
                      <label for="mce-FNAME">First Name</label>
                      <input style="height: 50px;" type="text" name="FNAME" class="text" id="mce-FNAME"
                        required="" value="">
                    </div>
                  </v-sheet>
                </v-col>

                <v-col class="popregular" cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                  <v-sheet class="ma-2 pa-2">
                    <div class="mc-field-group"><label for="mce-LNAME">Last Name </label><input
                        style="height: 50px;" type="text" name="LNAME" class=" text" id="mce-LNAME" required=""
                        value=""></div>

                  </v-sheet>
                </v-col>
                <v-col class="popregular" cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                  <v-sheet class="ma-2 pa-2">
                    <div class="mc-field-group"><label for="mce-EMAIL">Email Address</label><input
                        style="height: 50px;" type="email" name="EMAIL" class="email" id="mce-EMAIL"
                        required="" value=""></div>

                  </v-sheet>
                </v-col>
                <v-col class="popregular" cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                  <v-sheet>
                    <div class="mc-field-group"><label for="mce-EMAIL" style="color:#fff">Subscribe</label>
                    <input type="submit" name="subscribe" id="mc-embedded-subscribe" class="btn" value="Subscribe Now"
                      style="height: 50px;"></div>
                  </v-sheet>
                </v-col>
                <div hidden=""><input type="hidden" name="tags" value="3996148"></div>

              </v-row>
              <div id="mce-responses" class="clear">
                <div class="response" id="mce-error-response" style="display: none;"></div>
                <div class="response" id="mce-success-response" style="display: none;"></div>
              </div>
              <div aria-hidden="true" style="position: absolute; left: -5000px;"><input type="text" name="b_2c47e36b829020c293c520c3f_e29f2715de" tabindex="-1" value=""></div>
            
              <div class="clear">

              </div>


            </div>
          </form>
        </div>
      </v-flex>
    </v-layout>
    <!-- <div id="mc_embed_signup">
        <form
          action="https://wti.us20.list-manage.com/subscribe/post?u=2c47e36b829020c293c520c3f&amp;id=e29f2715de&amp;f_id=009c0deaf0"
          method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate "
          target="_blank">
          <div id="mc_embed_signup_scroll">
            <v-container class="bg-surface-varian custom-card">
              <v-row>
                <span  style="font-size:17px;font-weight:bolder" >Subscribe to our newsletter</span>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="3">
                  <v-sheet class="ma-2 pa-2">
                    <div class="mc-field-group"><label for="mce-FNAME">First Name </label><input type="text" name="FNAME"
                        class=" text" id="mce-FNAME" required="" value=""></div>
  
                  </v-sheet>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-sheet class="ma-2 pa-2">
                    <div class="mc-field-group"><label for="mce-LNAME">Last Name </label><input type="text" name="LNAME"
                        class=" text" id="mce-LNAME" required="" value=""></div>
  
                  </v-sheet>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-sheet class="ma-2 pa-2">
                    <div class="mc-field-group"><label for="mce-EMAIL">Email Address</label><input type="email"
                        name="EMAIL" class="email" id="mce-EMAIL" required="" value=""></div>
  
                  </v-sheet>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-sheet class="ma-2 pa-2">
                    <input type="submit" name="subscribe" id="mc-embedded-subscribe" class="btn" value="Subscribe Now">
                  </v-sheet>
                </v-col>
  
              </v-row>
            </v-container>
            <div id="mce-responses" class="clear">
              <div class="response" id="mce-error-response" style="display: none;"></div>
              <div class="response" id="mce-success-response" style="display: none;"></div>
            </div>
            <div aria-hidden="true" style="position: absolute; left: -5000px;">
              <input type="text" name="b_2c47e36b829020c293c520c3f_e29f2715de" tabindex="-1" value="">
            </div>
            <div class="clear">
  
            </div>
  
  
          </div>
        </form>
      </div> -->
  </div>
</template>
  <script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script><script type="text/javascript">
(function ($) {
  window.fnames = new Array();
  window.ftypes = new Array();
  fnames[1] = "FNAME";
  ftypes[1] = "text";
  fnames[2] = "LNAME";
  ftypes[2] = "text";
  fnames[0] = "EMAIL";
  ftypes[0] = "email";
  fnames[3] = "ADDRESS";
  ftypes[3] = "address";
  fnames[4] = "PHONE";
  ftypes[4] = "phone";
  fnames[5] = "BIRTHDAY";
  ftypes[5] = "birthday";
})(jQuery);
var $mcj = jQuery.noConflict(true);
</script>
  <script>
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 1000,
      msg: null,
      icons: [
        {
          name: require("./../../assets/Images/testimonial1.jpeg"),
          heading: "Aarav Sharma",
          location: "New Delhi",
          text: "I've always loved wildlife, so when I learned about WTI's work, I knew I had to get involved. They're doing amazing things to protect animals from poaching and trafficking, and I'm proud to support their efforts. Every little bit helps, and I'm glad to be a part of the solution.",
        },

        {
          name: require("./../../assets/Images/testimonial2.6.jpeg"),
          heading: "Nisha Patel",
          location: "Gurugram",
          text: '"I\'m proud to support WTI in their crucial work. Knowing that my donation contributes to protecting endangered species and preserving our natural heritage gives me hope for a better future for wildlife and generations to come."',
        },
        {
          name: require("./../../assets/Images/testimonial3.jpeg"),
          heading: "Vikram Singh",
          location: "Mumbai",
          text: "\"As a wildlife enthusiast, I've seen first-hand the devastating impacts of illegal wildlife trade. That's why I chose to support WTI in their tireless efforts to combat this issue. Their dedication and innovative approaches inspire me, and I'm honoured to be part of their mission to protect wildlife.\"",
        },
      ],
    };
  },
};
</script>
  <style>
.btn {
  background-color: #3d673a;
  /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
.custom-card {
  border: 2px solid black;
  border-radius: 0;
  /* Increase the border thickness as needed */
}
</style>