<template>
    <div>
     <Banner/>
     <Donation/>
     <!-- <Show/> -->
     <!-- <Dmethods/> -->
     <!-- <Ourimpact/> -->
     <testimonial/>
     <!-- <Partners/> -->
     <!-- <Bottom/> -->



    </div>
</template>
<script>
import Banner from "./banner";
import Donation from "./donationForm";
// import Show from "./showYourSupport";
// import Dmethods from "./donationMethods";
// import Ourimpact from "./ourImpact";
// import Partners from "./partners";
// import Bottom from "./bottom";
import testimonial from "./testimonials";




export default {
    components:{
        Banner,
        Donation,
        // Show,
        // Dmethods,
        // Ourimpact,
        // Partners,
        testimonial,
        // Bottom
    }
    
}   
</script>